.case-dashboard {
  margin: auto;
  margin-top: 35px;
  margin-bottom: 95px;
  background: #e9ecef;
  border-radius: 7px;
  overflow: auto;
  max-height: 60vh;
  max-width: 1600px;
}

.case-dashboard-wrapper {
  overflow-x: hidden;
}

.dashboard-wrapper {
  margin: 30px 25px;
}

.case-info-wrapper {
  background: white;
  padding: 3px;
  margin: 4px;
  text-decoration: none;
  border: 1px solid white;
}

.case-info-wrapper:hover {
  border: 1px solid #777;
}

.case-list {
  margin: auto;
  padding: 10px;
}

.case-info {
  padding-left: 10px;
  padding-top: 5px;
}

.add-case-icon {
  float: left;
}

.delete-case-button {
  float: right;
}

.delete-case-button:focus {
  outline: none;
}

.delete-case-text {
  margin: 5px 0px;
}

.add-case-button-text {
  margin-left: 3px;
  float: left;
}

.add-case-button {
  margin: 7px 25px 0px 0px;
  float: right;
}

.add-case-button:focus {
  outline: none;
}

.case-dashboard-nav {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.pill-wrapper {
  padding-left: 30%;
  margin-top: 5px;
}

.pill-wrapper .nav-link.active {
  color: #007bff;
  background-color: white;
  border: 1px solid #007bff;
}

.search-input {
  float: left;
  margin-left: 25px;
  padding: 10px 10px;
  height: 52px;
  position: relative;
}

.search-input::before {
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==");
  display: block;
  position: absolute;
  width: 15px;
  z-index: 3;
  height: 15px;
  font-size: 20px;
  top: 11px;
  left: 16px;
  line-height: 32px;
  opacity: 0.6;
}

.search-input > input {
  border-radius: 15px;
  width: 85%;
  font-size: 14px;
  border: 1px solid grey;
  line-height: 22px;
  padding: 5px 5px 5px 25px;
  height: 32px;
  position: relative;
  background: white;
}

.search-input > input:focus {
  outline: none;
}
