.pricing-header {
  text-align: center;
  margin: 15px 0px;
}

.pricing-types {
  text-align: center;
}

.info-p {
  text-align: center;
  margin: 35px 0px;
}

.navbar {
  padding: 0px;
}

.page-header {
  text-align: left;
  padding-left: 25px;
  padding-top: 15px;
}

.price-title {
  margin: 25px;
}

.price {
  text-align: center;
  font-style: italic;
  color: grey;
  font-size: 32px;
  font-weight: 1200px;
}

.desc {
  text-align: center;
  margin-top: 25px;
}
