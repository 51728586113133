.app {
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}

#user-dropdown {
  text-decoration: underline;
  border-radius: 5px;
}

.navbar {
  margin: auto;
}

#breadcrumb {
  background: white;
  margin-bottom: 0px;
}

#tab-content {
  float: right;
  background-color: white;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.app-nav-container {
  margin: 15px 0px 0px 0px;
  padding-bottom: 5px;
  border-bottom: none;
}

.tab-active {
  color: black;
}

.card-err-message {
  margin: 20px 15px;
  text-align: center;
  font-weight: bold;
}

.not-available-edge {
  font-size: 10px;
}

.loading-icon {
  margin: auto;
  text-align: center;
  padding-left: 45vw;
}

.loading-icon-wrapper {
  padding-top: 30vh;
  margin: auto;
}
