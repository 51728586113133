.section {
  margin: auto;
  margin-top: 35px;
  margin-bottom: 35px;
  padding: 30px;
  max-width: 650px;
  min-height: 350px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.plan-name {
  font-size: 24px;
  color: grey;
  font-style: italic;
}

.button-acct {
  float: right;
  margin: 20px 0px;
}

.button-wrapper {
  height: 45px;
  margin-bottom: 20px;
}

h4 {
  text-align: center;
  margin-bottom: 25px;
}

.account-page {
  padding: 0px 25px;
}

.nav-section-account {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 10px;
  margin: auto;
  margin-top: 35px;
  min-width: 260px;
  max-width: 400px;
}
