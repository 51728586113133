.case-name-delete {
  font-weight: bold;
}

.delete-case-button {
  float: right;
  margin-left: 10px;
  background: none;
}

.delete-case-button:hover {
  color: white;
}

.button-wrapper-case-element {
  float: right;
}

.case-info-wrapper {
  border-radius: 5px;
}

.date-moved {
  padding-top: 6px;
}
