.custom-nav {
  background: white;
  font-size: 17px;
  padding: 7px;
  box-shadow: none;
  max-width: 100vw;
  border-bottom: 0.25px solid rgba(175, 175, 175, 0.99);
  overflow-y: visible;
}

.custom-nav-header {
  color: #3b83ff;
  font-size: 30px;
  padding: 20px;
}

.auth-buttons-nav {
  position: absolute;
  top: 8px;
  right: 20px;
}

.nav-alert {
  text-align: center;
}

#user-dropdown {
  text-decoration: none;
  color: black;
  padding: 8px;
}
