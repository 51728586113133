.form-page {
  height: 100vh;
  width: 100vw;
  padding-top: 12vh;
  padding-bottom: 12vh;
}

.form-wrapper {
  margin: auto;
  padding: 25px;
  max-width: 650px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.submit-login-form {
  float: left;
}

.form-brand-header {
  text-align: center;
  color: #199fe8;
  font-size: 65px;
}

.form-brand-header:hover {
  text-decoration: none;
}

.submit-form-button {
  float: right;
}

.form-footer {
  padding-top: 30px;
}

.form-footer-link {
  padding: 8px;
}

.create-account {
  border-right: 1px solid grey;
}

.confirm-account-create {
  padding: 20px;
  text-align: center;
}

.back-to-login {
  padding: 10px;
}

.icon-wrapper {
  text-align: center;
  margin-bottom: 25px;
}

.form-wrapper hr {
  border-color: rgba(0, 0, 0, 0.25);
}

h3 {
  text-align: left;
  margin: 25px 0px;
}

hr {
  margin: 25px 0px;
}

.trial-message-bold {
  font-weight: bold;
}
