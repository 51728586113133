.home {
  max-width: 100vw;
  overflow: hidden;
}

.jumbo-button {
  background: orange;
  border: none;
  margin: 0px 5px 0px 0px;
  padding: 10px;
}

img.screensnip {
  width: 140%;
  height: auto;
  margin-top: 50px;
  max-width: 750px;
}

.tagline {
  font-size: 11px;
  color: #007bff;
  margin-left: 20px;
}
