.main-header {
  text-align: left;
  max-width: 1000px;
  font-weight: 700;
  font-size: 40px;
}

.main-desc {
  text-align: left;
  color: white;
  font-size: 1rem;
  font-weight: 400;
  max-width: 400px;
}

.main-workflow-header {
  font-weight: bold;
}

.main-workflow {
  text-align: center;
  padding: 15px;
}

.main-row {
  max-width: 1600px;
  margin: auto;
}

.main-workflow-image {
  border: 1px solid black;
  margin: auto;
  width: 300px;
  height: auto;
}

.main-devices {
  text-align: center;
  padding: 15px;
}

.main-demo-button {
  text-align: center;
  padding: 25px;
}

.call-to-action-button {
  letter-spacing: 1px;
  display: inline-block;
  border-radius: 45px;
  padding: 12px 36px;
  font-size: 24px;
  font-weight: 500;
}

.call-to-action-button:hover {
  color: #3b83ff;
  border-color: 1px #3b83ff solid;
  background: white;
}

.main-create-account-button {
  text-align: left;
  padding-bottom: 25px;
  padding-left: 0px;
}

.main-review {
  text-align: center;
  padding: 100px;
}

.demo-section {
  margin: 45px 0px;
}

.state-alert {
  text-align: center;
  font-size: 12px;
  margin-bottom: 0px;
  border-radius: 0px;
}

.jumbo {
  background: #007bff;
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.jumbo-content {
  padding-top: 45px;
  margin-left: 25px;
  margin-right: 25px;
}

.jumbo-content .main-header {
  color: white;
}

.demo-section {
  background: url("/img/cta-back.jpg");
}

.demo-content {
  text-align: center;
  color: white;
}

.main-workflow .card {
  border: none;
}

.main-devices-col .card {
  border: none;
}

.browsers {
  text-align: left;
  margin-bottom: 85px;
}

.browsers .card {
  margin-top: 12px;
  border: none;
}

.browser-icon {
  width: 75px;
  height: 75px;
  margin: auto;
}

.icon-wrapper {
  width: 100%;
  text-align: left;
}

.browsers span {
  font-style: italic;
  font-size: 14px;
  color: #ccc;
}

.browser-disclaimer {
  margin: auto;
  padding: 25px;
  padding-top: 0px;
}

.p-wrapper {
  width: 100%;
}

.main-summary {
  overflow-x: hidden;
}

.pricing-link {
  text-align: center;
  color: white;
}
