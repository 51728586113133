.page-header {
  text-align: left;
  padding-left: 25px;
  padding-top: 15px;
}

.contact-card {
  max-width: 700px;
  margin: auto;
  margin-top: 45px;
}

.contact-card img {
  margin: 16px 0px;
  height: 135px;
  width: 135px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.email {
  font-size: 24px;
  margin-top: 45px;
  text-align: left;
  margin-left: 35px;
}

.phone {
  font-size: 18px;
  margin-top: 12px;
  text-align: left;
  margin-left: 35px;
  margin-bottom: 25px;
}

.name {
  color: grey;
  font-style: italic;
  font-weight: 500;
}

.home {
  margin-bottom: 105px;
}
