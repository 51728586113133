.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  margin: 20px 10px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 2px 2px 4px 2px #d8dde2;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 1px 1px 3px 1px #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.signup-confirm {
  margin: 35px 0px;
}

.bottom-div {
  min-height: 65px;
  margin-top: 45px;
  margin-bottom: 5px;
}

.stripe-badge {
  margin-left: 10px;
  margin-bottom: 12px;
}
