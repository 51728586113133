.smooth-dnd-container.horizontal {
  margin: auto;
  margin-top: 5px;
}

.footer {
  position: fixed;
  bottom: 0;
}

.app {
  max-width: 100%;
  overflow-x: hidden;
  font-family: "Montserrat", sans-serif;
}

#user-dropdown {
  text-decoration: underline;
  border-radius: 5px;
}

.navbar {
  margin: auto;
}

#breadcrumb {
  background: white;
  margin-bottom: 0px;
}

#tab-content {
  float: right;
  background-color: white;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.app-nav-container {
  margin: 15px 0px 0px 0px;
  padding-bottom: 5px;
  border-bottom: none;
}

.tab-active {
  color: black;
}

.card-err-message {
  margin: 20px 15px;
  text-align: center;
  font-weight: bold;
}

.not-available-edge {
  font-size: 10px;
}

.loading-icon {
  margin: auto;
  text-align: center;
  padding-left: 45vw;
}

.loading-icon-wrapper {
  padding-top: 30vh;
  margin: auto;
}

.form-page {
  height: 100vh;
  width: 100vw;
  padding-top: 12vh;
  padding-bottom: 12vh;
}

.form-wrapper {
  margin: auto;
  padding: 25px;
  max-width: 650px;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.submit-login-form {
  float: left;
}

.form-brand-header {
  text-align: center;
  color: #199fe8;
  font-size: 65px;
}

.form-brand-header:hover {
  text-decoration: none;
}

.submit-form-button {
  float: right;
}

.form-footer {
  padding-top: 30px;
}

.form-footer-link {
  padding: 8px;
}

.create-account {
  border-right: 1px solid grey;
}

.confirm-account-create {
  padding: 20px;
  text-align: center;
}

.back-to-login {
  padding: 10px;
}

.icon-wrapper {
  text-align: center;
  margin-bottom: 25px;
}

.form-wrapper hr {
  border-color: rgba(0, 0, 0, 0.25);
}

h3 {
  text-align: left;
  margin: 25px 0px;
}

hr {
  margin: 25px 0px;
}

.trial-message-bold {
  font-weight: bold;
}

.case-dashboard {
  margin: auto;
  margin-top: 35px;
  margin-bottom: 95px;
  background: #e9ecef;
  border-radius: 7px;
  overflow: auto;
  max-height: 60vh;
  max-width: 1600px;
}

.case-dashboard-wrapper {
  overflow-x: hidden;
}

.dashboard-wrapper {
  margin: 30px 25px;
}

.case-info-wrapper {
  background: white;
  padding: 3px;
  margin: 4px;
  text-decoration: none;
  border: 1px solid white;
}

.case-info-wrapper:hover {
  border: 1px solid #777;
}

.case-list {
  margin: auto;
  padding: 10px;
}

.case-info {
  padding-left: 10px;
  padding-top: 5px;
}

.add-case-icon {
  float: left;
}

.delete-case-button {
  float: right;
}

.delete-case-button:focus {
  outline: none;
}

.delete-case-text {
  margin: 5px 0px;
}

.add-case-button-text {
  margin-left: 3px;
  float: left;
}

.add-case-button {
  margin: 7px 25px 0px 0px;
  float: right;
}

.add-case-button:focus {
  outline: none;
}

.case-dashboard-nav {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
}

.pill-wrapper {
  padding-left: 30%;
  margin-top: 5px;
}

.pill-wrapper .nav-link.active {
  color: #007bff;
  background-color: white;
  border: 1px solid #007bff;
}

.search-input {
  float: left;
  margin-left: 25px;
  padding: 10px 10px;
  height: 52px;
  position: relative;
}

.search-input::before {
  content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA8AAAAPCAYAAAA71pVKAAAAAXNSR0IArs4c6QAAAQJJREFUKBWVkr2uQUEUhf3ET6GRaC5aFRoJKrf1BKpb8SwqovYGXkCj00k0QnRKEYkILYobvpUYmeMMyVnJl7P3mjN7Zu9zwiGv2qRFyMMSRrAFp6JPN8XzBj+wgDkUYAg7WINTYdwpDECxrRLJHeq2accdkgm8bzTvNAg2EDOGeUYI1KNO1gkuzTA1g8T7ojbn4ONQWPuHPWgeHmnzCqoe15tkSNPgPEAn68oVcOmA2XMtGK9FoE/VhOTTVNExqLCGZnxCv2pYauEC6lF0oQxX6IOvb7yX9NPEQafan+aPXDdQC18LsO6Tip5BBY6gIQaSbnMCFRCBZRcIvFkbsvCr4AFGOCxQy+JdGQAAAABJRU5ErkJggg==");
  display: block;
  position: absolute;
  width: 15px;
  z-index: 3;
  height: 15px;
  font-size: 20px;
  top: 11px;
  left: 16px;
  line-height: 32px;
  opacity: 0.6;
}

.search-input > input {
  border-radius: 15px;
  width: 85%;
  font-size: 14px;
  border: 1px solid grey;
  line-height: 22px;
  padding: 5px 5px 5px 25px;
  height: 32px;
  position: relative;
  background: white;
}

.search-input > input:focus {
  outline: none;
}

.case-name-delete {
  font-weight: bold;
}

.delete-case-button {
  float: right;
  margin-left: 10px;
  background: none;
}

.delete-case-button:hover {
  color: white;
}

.button-wrapper-case-element {
  float: right;
}

.case-info-wrapper {
  border-radius: 5px;
}

.date-moved {
  padding-top: 6px;
}

.custom-nav {
  background: white;
  font-size: 17px;
  padding: 7px;
  box-shadow: none;
  max-width: 100vw;
  border-bottom: 0.25px solid rgba(175, 175, 175, 0.99);
  overflow-y: visible;
}

.custom-nav-header {
  color: #3b83ff;
  font-size: 30px;
  padding: 20px;
}

.auth-buttons-nav {
  position: absolute;
  top: 8px;
  right: 20px;
}

.nav-alert {
  text-align: center;
}

#user-dropdown {
  text-decoration: none;
  color: black;
  padding: 8px;
}

.footer {
  background: #e9ecef;
  position: relative;
  min-height: 40px;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  padding-top: 10px;
  overflow: hidden;
  font-size: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.footer-wrapper {
  text-align: center;
}

.main-header {
  text-align: left;
  max-width: 1000px;
  font-weight: 700;
  font-size: 40px;
}

.main-desc {
  text-align: left;
  color: white;
  font-size: 1rem;
  font-weight: 400;
  max-width: 400px;
}

.main-workflow-header {
  font-weight: bold;
}

.main-workflow {
  text-align: center;
  padding: 15px;
}

.main-row {
  max-width: 1600px;
  margin: auto;
}

.main-workflow-image {
  border: 1px solid black;
  margin: auto;
  width: 300px;
  height: auto;
}

.main-devices {
  text-align: center;
  padding: 15px;
}

.main-demo-button {
  text-align: center;
  padding: 25px;
}

.call-to-action-button {
  letter-spacing: 1px;
  display: inline-block;
  border-radius: 45px;
  padding: 12px 36px;
  font-size: 24px;
  font-weight: 500;
}

.call-to-action-button:hover {
  color: #3b83ff;
  border-color: 1px #3b83ff solid;
  background: white;
}

.main-create-account-button {
  text-align: left;
  padding-bottom: 25px;
  padding-left: 0px;
}

.main-review {
  text-align: center;
  padding: 100px;
}

.demo-section {
  margin: 45px 0px;
}

.state-alert {
  text-align: center;
  font-size: 12px;
  margin-bottom: 0px;
  border-radius: 0px;
}

.jumbo {
  background: #007bff;
  width: 100%;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.jumbo-content {
  padding-top: 45px;
  margin-left: 25px;
  margin-right: 25px;
}

.jumbo-content .main-header {
  color: white;
}

.demo-section {
  background: url("/img/cta-back.jpg");
}

.demo-content {
  text-align: center;
  color: white;
}

.main-workflow .card {
  border: none;
}

.main-devices-col .card {
  border: none;
}

.browsers {
  text-align: left;
  margin-bottom: 85px;
}

.browsers .card {
  margin-top: 12px;
  border: none;
}

.browser-icon {
  width: 75px;
  height: 75px;
  margin: auto;
}

.icon-wrapper {
  width: 100%;
  text-align: left;
}

.browsers span {
  font-style: italic;
  font-size: 14px;
  color: #ccc;
}

.browser-disclaimer {
  margin: auto;
  padding: 25px;
  padding-top: 0px;
}

.p-wrapper {
  width: 100%;
}

.main-summary {
  overflow-x: hidden;
}

.pricing-link {
  text-align: center;
  color: white;
}

.home {
  max-width: 100vw;
  overflow: hidden;
}

.jumbo-button {
  background: orange;
  border: none;
  margin: 0px 5px 0px 0px;
  padding: 10px;
}

img.screensnip {
  width: 140%;
  height: auto;
  margin-top: 50px;
  max-width: 750px;
}

.tagline {
  font-size: 11px;
  color: #007bff;
  margin-left: 20px;
}

.pricing-header {
  text-align: center;
  margin: 15px 0px;
}

.pricing-types {
  text-align: center;
}

.info-p {
  text-align: center;
  margin: 35px 0px;
}

.navbar {
  padding: 0px;
}

.page-header {
  text-align: left;
  padding-left: 25px;
  padding-top: 15px;
}

.price-title {
  margin: 25px;
}

.price {
  text-align: center;
  font-style: italic;
  color: grey;
  font-size: 32px;
  font-weight: 1200px;
}

.desc {
  text-align: center;
  margin-top: 25px;
}

.page-header {
  text-align: left;
  padding-left: 25px;
  padding-top: 15px;
}

.contact-card {
  max-width: 700px;
  margin: auto;
  margin-top: 45px;
}

.contact-card img {
  margin: 16px 0px;
  height: 135px;
  width: 135px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.6);
}

.email {
  font-size: 24px;
  margin-top: 45px;
  text-align: left;
  margin-left: 35px;
}

.phone {
  font-size: 18px;
  margin-top: 12px;
  text-align: left;
  margin-left: 35px;
  margin-bottom: 25px;
}

.name {
  color: grey;
  font-style: italic;
  font-weight: 500;
}

.home {
  margin-bottom: 105px;
}

ul {
  list-style-type: none;
}

.StripeElement {
  background-color: white;
  height: 40px;
  padding: 10px 12px;
  margin: 20px 10px;
  border-radius: 4px;
  border: 1px solid transparent;
  box-shadow: 2px 2px 4px 2px #d8dde2;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 1px 1px 3px 1px #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.signup-confirm {
  margin: 35px 0px;
}

.bottom-div {
  min-height: 65px;
  margin-top: 45px;
  margin-bottom: 5px;
}

.stripe-badge {
  margin-left: 10px;
  margin-bottom: 12px;
}

.section {
  margin: auto;
  margin-top: 35px;
  margin-bottom: 35px;
  padding: 30px;
  max-width: 650px;
  min-height: 350px;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
}

.plan-name {
  font-size: 24px;
  color: grey;
  font-style: italic;
}

.button-acct {
  float: right;
  margin: 20px 0px;
}

.button-wrapper {
  height: 45px;
  margin-bottom: 20px;
}

h4 {
  text-align: center;
  margin-bottom: 25px;
}

.account-page {
  padding: 0px 25px;
}

.nav-section-account {
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  padding: 10px;
  margin: auto;
  margin-top: 35px;
  min-width: 260px;
  max-width: 400px;
}

