.footer {
  background: #e9ecef;
  position: relative;
  min-height: 40px;
  width: 100%;
  text-align: center;
  margin-top: 15px;
  padding-top: 10px;
  overflow: hidden;
  font-size: 12px;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.footer-wrapper {
  text-align: center;
}
